import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BeFeaturedBanner from "../../components/beFeaturedBanner"
import FeaturingSpeakerCard from "../../components/featuringSpeakerCard"
import futureDrivenLogo from "../../images/logos/Future_Driven_2020_KO_Lockup.png"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { motion } from "framer-motion"
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

function FutureDrivenPage({ data }) {
  const site = (data || {}).site
  const sponsors = (data || {}).sponsors

  const swiperBreakpoints = {
    768: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
    1920: {
      slidesPerView: 5,
    },
  }

  const swiperAutoplay = {
    delay: 2500,
    disableOnInteraction: false,
  }

  return (
    <Layout title="programs">
      <Seo title="Future Driven Program" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex items-center justify-center py-16 w-full text-center bg-future-driven-header bg-cover bg-center bg-no-repeat">
          <img
            className="w-auto lg:max-w-2xl"
            src={futureDrivenLogo}
            alt="Future Driven Logo"
          />
        </section>
        <section className="flex flex-col justify-center px-8 py-16 w-full bg-white-left-starburst bg-cover bg-center bg-no-repeat space-y-8 md:flex-row md:space-x-8 md:space-y-0 lg:px-0 lg:py-24">
          <div className="max-w-2xl space-y-6">
            <h1 className="mb-4 text-gray-500 text-xl font-bold lg:text-3xl">
              About the {site.edges[0].node.title} Series
            </h1>
            <p className="text-gray-500 whitespace-pre-line text-sm lg:text-base">
              {site.edges[0].node.description}
            </p>
            <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:items-stretch md:justify-start md:space-x-4 md:space-y-0">
            {site.edges[0].node.eventURL !== null && (
              <a
                href={site.edges[0].node.eventURL}
                target="_blank"
                rel="noopener noferrer"
              >
                <button className="min-w-150 px-4 py-2 text-gray-500 hover:text-white font-bold hover:bg-secondary-200 border-2 border-secondary-200 rounded-full focus:outline-none transition">
                  REGISTER
                </button>
              </a>
            )}
              <Link to="/resources?q=webinars">
                <button
                  className="border-2
								font-bold
								text-gray-500
								border-secondary-200
								py-2
								px-4
								min-w-150
								rounded-full
								transition
								focus:outline-none
								hover:bg-secondary-200
								hover:text-white
								flex
								items-center"
                >
                  <svg
                    width="16"
                    height="16"
                    className="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.5547 5.16795C7.24784 4.96338 6.8533 4.94431 6.52814 5.11833C6.20298 5.29235 6 5.63121 6 6V10C6 10.3688 6.20298 10.7077 6.52814 10.8817C6.8533 11.0557 7.24784 11.0366 7.5547 10.8321L10.5547 8.8321C10.8329 8.6466 11 8.3344 11 8C11 7.66565 10.8329 7.35342 10.5547 7.16795L7.5547 5.16795Z"
                      className="fill-current"
                    />
                  </svg>
                  WATCH NOW
                </button>
              </Link>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex flex-col justify-center p-8 w-full h-48 text-white bg-secondary-200 space-y-4 md:w-72">
              <h3 className="text-xl">Be a Program Sponsor</h3>
              <div>
                <Link to="/sponsorship">
                  <button className="min-w-150 px-4 py-2 hover:text-black text-white text-sm font-bold hover:bg-white border-2 border-white rounded-full focus:outline-none transition">
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col justify-center w-full lg:flex-row">
          <div className="flex flex-col justify-center p-8 w-full text-center bg-gray-500 lg:w-1/4 lg:text-right">
            <h3 className="text-yellow-400 text-xl italic font-bold lg:text-2xl">
              Featuring
            </h3>
          </div>
          <div className="mx-auto p-4 w-full bg-gray-300 overflow-x-hidden">
            <Swiper
              slidesPerView={2}
              breakpoints={swiperBreakpoints}
              loop={false}
            >
              {site &&
                site.edges[0].node.guests.map((guest, i) => (
                  <SwiperSlide key={i}>
                    <FeaturingSpeakerCard
                      key={i}
                      name={guest.name}
                      position={guest.position}
                      image={guest.image.asset.url}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
        <section className="py-8 w-full text-center bg-white lg:py-16">
          <h3 className="mb-4 text-gray-600 text-2xl font-bold lg:text-3xl">
            Our {new Date().getFullYear()} Sponsors
          </h3>
          <div className="fade-edges relative mx-auto w-full overflow-x-hidden lg:w-10/12">
            <Swiper
              slidesPerView={2}
              breakpoints={swiperBreakpoints}
              autoplay={swiperAutoplay}
              loop={true}
            >
              {sponsors &&
                sponsors.edges
                  .filter(
                    sponsor =>
                      sponsor.node.year.some(
                        element =>
                          element.value === new Date().getFullYear().toString()
                      ) &&
                      sponsor.node.sponsorTypes.some(
                        sponsor => sponsor.name === "Future Driven Webinar"
                      )
                  )
                  .map((sponsor, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={sponsor.node.company.url}
                        key={i}
                        target="_blank"
                        rel="noreferrer"
                        className="grayscale-effect relative block transition-all"
                      >
                        <img
                          src={sponsor.node.company.logo.asset.url}
                          alt={sponsor.node.company.name}
                          className="mx-auto w-32 h-16 object-contain object-center"
                        />
                      </a>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default FutureDrivenPage

export const query = graphql`
  {
    site: allSanityFutureDrivenWebinar {
      edges {
        node {
          title
          guests {
            bio
            company {
              url
              name
              logo {
                asset {
                  url
                }
              }
            }
            name
            position
            image {
              asset {
                url
              }
            }
          }
          description
          eventURL
        }
      }
    }
    sponsors: allSanitySponsor {
      edges {
        node {
          company {
            logo {
              asset {
                url
              }
            }
            url
            name
          }
          year {
            value
          }
          sponsorTypes {
            name
          }
        }
      }
    }
  }
`
